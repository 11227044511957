import React, {useState, ReactNode} from 'react';

interface LayoutProps {
    children: ReactNode;
}

const FullscreenModalWrapper = ({children}: LayoutProps) => {
    return (
        <div className='fixed w-full h-full bg-gray-900/50 dark:bg-gray-900/80 inset-0 z-40 top-0 left-0 py-10'>
            <div
                className="container bg-white overflow-y-auto overflow-x-hidden fixed top-10 my-auto right-0 left-0 z-50 px-10 md:inset-0 h-[calc(80%-1rem)] max-h-full rounded"
            >
                {children}
            </div>
        </div>
    );
};

export default FullscreenModalWrapper
import React, {useState, ReactNode} from 'react';
import Logo from '../logo';

interface LayoutProps {
    children: ReactNode;
  }

const GuestLayout : React.FC <LayoutProps>= ({children}) => {
    return (
        <div className='grid gap-4 lg:grid-cols-2'>
            <div className='flex items-center justify-center p-10 lg:h-screen bg-primary'>
                <Logo width='w-[7em]' height='h-[7em]'/>
            </div>
            <div className='flex items-center justify-center'>
                <div className='p-5 rounded shadow-sm lg:w-4/5 h-fit'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default GuestLayout
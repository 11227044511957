import { useState, useEffect } from "react";
import AuthLayout from "../../components/layouts/auth"
import ApiRequest from "../../lib/ApiCall";
import { numberWithCommas } from "../../lib/helper-support";
import { SuccessFlash } from "../../lib/flash-notification";
import { get } from "react-hook-form";
import SingleProduct from "./product/single-product";

const Dashboard = () => {

    type ProductType = {
        status: string;
        issuer: string;
        type: string;
        amount: string|number,
        interestRate: string|number,
        series: string,
        offerOpenDate:string,
        offerCloseDate:string,
        issuingHouses:string[],
        tenor: string,
        tenorIn: string,  
        amountCurrency: string,
        postedBy: string,
        uuid: string,
    }

    interface OverviewType{
        latestProducts: ProductType [],
        totalMembers:string,
        totalProducts:string|number,
        totalPendingMembers:string,
        totalPendingProducts:string,
    }

    const [overview, setOverview] = useState<OverviewType>({
        
    } as OverviewType);

    const [products, setProducts] = useState<ProductType[]>([]);

    const getOverview = async () => {
        const request = await ApiRequest({endpoint: 'dashboard', method: 'GET'});
        request.status === '200' && setOverview(request.data);
    }

    useEffect(() => {getOverview()}, []);

    const onProductUpdate =  (product:any) => {
        //setProducts(products.map((item) => item.uuid === product.uuid ? {...product} : item));   
        getOverview();
    }
    const onProductDelete = async (product:any) => {
        //setProducts(products.filter((item) => item.uuid !== product.uuid));
        //setOverview({...overview, totalProducts: Number(overview.totalProducts) - 1});
        await getOverview();
        SuccessFlash('Product deleted successfully')
    }
    
    return(
        <AuthLayout>
            <div>
                <h1 className="page-title">Dashboard</h1>
                <div className="grid gap-4 mt-5 lg:grid-cols-4">
                    <div className=" p-5 rounded-[10px] text-black bg-gray-50 border">
                        <span className="font-light">Total members</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalMembers}</h3>
                    </div>
                    <div className=" text-black p-5 rounded-[10px] border bg-gray-50">
                        <span className="font-light">Pending members</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalPendingMembers}</h3>
                    </div>
                    <div className=" text-black p-5 rounded-[10px] border bg-gray-50">
                        <span className="font-light">Total products</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalProducts}</h3>
                    </div>
                    <div className=" text-black p-5 rounded-[10px] border bg-gray-50">
                        <span className="font-light">Pending products</span>
                        <h3 className="font-[900] text-[1.5em]">{overview.totalPendingProducts}</h3>
                    </div>
                </div>
                <div>
                    <h1 className="mt-10">Latest Products</h1>
                </div>
                <div className='relative overflow-x-auto'>
                    <table className='mt-1 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Posted By</td>
                                <td className="px-4 py-3">Issuer</td>
                                <td className="px-6 py-3">Type</td>
                                <td className="px-6 py-3">Amount</td>
                                <td className="px-6 py-3 text-center">Interest Rate</td>
                                <td className="px-6 py-3">Tenor</td>
                                <td className="px-6 py-3">Status</td>
                                <td className="px-6 py-3">Action</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {overview.latestProducts?.map((product, i) => (
                                <tr key={i} className='border-b border-[#E6EAF0]'>
                                    <td className="px-4 py-3 capitalize">{product.postedBy}</td>
                                    <td className="px-4 py-3">{product.issuer}</td>
                                    <td className="px-6 py-3">{product.type}</td>
                                    <td className="px-6 py-3">{product.amountCurrency} {numberWithCommas(product.amount)}</td>
                                    <td className="px-6 py-3 text-center">{product.interestRate}</td>
                                    <td className="px-6 py-3 capitalize">{product.tenor} {product.tenorIn}</td>
                                    <td className={`px-6 py-3 text-xs uppercase ${product.status === 'published' ? 'text-green-500' : 'text-yellow-500'}`}>{product.status}</td>
                                    <td className="px-6 py-3 text-center">
                                        {<SingleProduct productId={product.uuid} onProductUpdate={onProductUpdate} onProductDelete={onProductDelete}/>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </AuthLayout>
    )
}

export default Dashboard
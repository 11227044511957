import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Auth from "./auth";
import Guest from "./guest";
import NotFound from './not-found';
import React from 'react';

const Routes = () => {
    const appRoutes = [...Auth, ...Guest, { path: '*', element: <NotFound /> }];
    return useRoutes(appRoutes);
}

const AppRoutes : React.FC = () => {
    return (
        <Router>
            <Routes />
        </Router>
    );
}

export default AppRoutes;

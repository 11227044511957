type onChangeInputFunc = (e:React.ChangeEvent<HTMLTextAreaElement>) => void

type InputType = {
    placeholder?:string,
    label?: string,
    id?: string,
    value?:string,
    error?:string|null,
    onChangeInput?: onChangeInputFunc,
}

const TextareaField = ({
    placeholder,
    label,
    id,
    value,
    error,
    onChangeInput
} : InputType) => {
    const Id = id ?? label;

    return(
        <label htmlFor={Id} className="label">
            <span>{label}</span>
            <textarea 
                placeholder={placeholder ?? `Enter ${label}`}
                className={`input py-3 border-[#E8ECEF] border h-full ${error && 'border-red-500'}`}
                onInput={onChangeInput}
                rows={2} cols={200}
                value={value}
            />
            {error && <p className="my-1 text-red-500 label">{error}</p>}
        </label>
    );
}

export default TextareaField;
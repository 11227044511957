import { CircleX, EllipsisVertical, Eye, FileCheck2, FilePenLine, Trash2 } from "lucide-react";
import FullscreenModalWrapper from "../../../components/modal/wrapper/fullscreen-modal-wrapper";
import { useEffect, useRef, useState } from "react";
import EditProduct from "./edit-product";
import DefaultModal from "../../../components/modal/default-modal";
import ApiRequest from "../../../lib/ApiCall";
import { SuccessFlash } from "../../../lib/flash-notification";
import SpinnerLoader from "../../../components/loader/spinner-loader";
import { numberWithCommas } from "../../../lib/helper-support";

interface SingleProductType {
    productId: string,
    onProductUpdate: (product:any) => void,
    onProductDelete: (product:any) => void
}

const SingleProduct = ({productId, onProductUpdate, onProductDelete} : SingleProductType) => {
    type ProductType = {
        status: string;
        issuer: string;
        type: string;
        amount: string|number,
        interestRate: string|number,
        series: string,
        offerOpenDate:string,
        offerCloseDate:string,
        issuingHouses:string[],
        tenor: string,
        tenorIn: string,  
        amountCurrency: string,
        postedBy: string,
        uuid: string,
        isApproved: boolean,
        rightRatio:string,
        offerPrice:string,
        offerPriceCurrency: string,
        typeId: string,
    }
    
    const [product, setProduct] = useState<ProductType>({} as ProductType);

    const [showProductModal, setShowProductModal] = useState<boolean>(false);
    const [showEditProductModal, setShowEditProductModal] = useState<boolean>(false);

    const [showOption, setShowOption] = useState<boolean>(false);
    
    const showOptionRef = useRef<HTMLDivElement>(null);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const onShowOption = (triggerBy = 'default') => {
        triggerBy === 'outsideEvent'  ? setShowOption(false) : setShowOption(!showOption)
    };

    const handleOnClickOutside = (event: MouseEvent) => {
        if (showOptionRef.current && ! showOptionRef.current.contains(event.target as Node))  onShowOption('outsideEvent');
    }

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('click', handleOnClickOutside);
        
        // Cleanup event listener when the component unmounts
        return () => {
        document.removeEventListener('mousedown', handleOnClickOutside);
        };
      });

    const getProduct = async () => {
        const request = await ApiRequest({endpoint:`/product/${productId}`, method:'GET'});
        if (request.status !== '200') return;
        setProduct(request.data);
        setShowProductModal(true)
    }

    const publishProduct = async () => {
        setLoadingSubmit(true);
        const request = await ApiRequest({endpoint:`/product/approval`, method:'POST', payload: {
            productId,
            status: 'approved',
        }});
        setLoadingSubmit(false);
        if (request.status !== '200') return;
        setProduct(request.data);
        onProductUpdate(request.data);
        SuccessFlash('Product published successfully');
    } 

    const deleteProduct = async () => {
        const request = await ApiRequest({endpoint:`/product/${productId}/delete`, method:'POST'});
        if (request.status !== '200') return;
        setShowProductModal(false)
        onProductDelete(product);
    }
    
    const onEditProduct = () => {
        setShowOption(false);
        setShowProductModal(false);
        setShowEditProductModal(true);
    }

    const onUpdateProduct = (product:any) => {
        onProductUpdate(product);
        setProduct(product);
        setShowEditProductModal(false);
        setShowProductModal(true);
        SuccessFlash('Product updated successfully');
    }

    const onCloseEditProduct = () => {
        setProduct(product);
        setShowEditProductModal(false);
        setShowProductModal(true);
    }
    
    return (
        <div>
            <div onClick={getProduct} className="justify-center px-1 space-x-2 text-xs cursor-pointer pryBtn">
                <span><Eye size={18}/></span>
                <span>Preview</span>
            </div>
            {showProductModal && <FullscreenModalWrapper>
            <div className="mt-10 text-left ">
            <button onClick={()=>{setShowOption(false); setShowProductModal(false)}} className="absolute top-3 right-5" type="button">
                <CircleX size={30}/>
            </button>
            <div className="items-center justify-between space-y-5 lg:flex lg:space-y-0">
                    <div className="flex items-center space-x-2">
                        <h1 className="text-sm page-title">{product.issuer}</h1>
                    </div>
                    <div className="justify-end mt-5 lg:flex lg:mt-0">
                        <div className="items-center space-y-2 label lg:flex lg:space-x-4 lg:space-y-0">
                            <div className="text-xs">Status:<p className={`mt-0 font-bold capitalize ${product.status === 'published' ? 'text-green-800' : 'text-yellow-800'}`}>{product.status}</p></div>
                            <div className="flex items-center space-x-4">
                                
                                {! product.isApproved && <button disabled={loadingSubmit} onClick={publishProduct} className={`${loadingSubmit && 'opacity-[0.5]'} pryBtn w-fit`} type="button">
                                    <FileCheck2 size={18}/>
                                    <span>Publish product</span>
                                    {loadingSubmit && <SpinnerLoader/>}
                                </button>}
                                <div ref={showOptionRef} className="relative">
                                    <EllipsisVertical className="cursor-pointer" onClick={()=>setShowOption(!showOption)} size={18}/>
                                    {showOption && (
                                        <div className="absolute top-5 right-0 bg-white w-[15em] border px-3 py-5 rounded shadow label">
                                            <div className="grid gap-y-5">
                                            <div onClick={onEditProduct} className="flex items-center space-x-2 cursor-pointer hover:text-secondary">
                                                <FilePenLine size={18}/>
                                                <span>Edit product</span>
                                            </div>
                                                <DefaultModal 
                                                    buttonTriggerClass="flex items-center space-x-2 cursor-pointer hover:text-red-800"
                                                    modalButtonTriggerText="Delete product"
                                                    buttonTriggerClassIcon={<Trash2 size={18}/>}
                                                    description={`Are you sure you want to delete this product?`}
                                                    onConfirmationAction={deleteProduct}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-4 mt-5 lg:grid-cols-2">
                    <div>
                        <div className="grid gap-4 lg:grid-cols-2">
                                <div>
                                    <p className="label">Issuer</p>
                                    <h3 className="font-bold">{product.issuer}</h3>
                                </div>
                                <div>
                                    <p className="label">Type</p>
                                    <h3 className="font-bold">{product.type}</h3>
                                </div>
                                <div>
                                    <p className="label">Amount</p>
                                    <h3 className="font-bold">{product.amountCurrency} {numberWithCommas(product.amount)}</h3>
                                </div>
                                <div>
                                    <p className="label">Offer Price</p>
                                    <h3 className="font-bold">{product.offerPriceCurrency} {numberWithCommas(product.offerPrice)}</h3>
                                </div>
                                <div>
                                    <p className="label">Interest Rate</p>
                                    <h3 className="font-bold">{product.interestRate}</h3>
                                </div>
                                <div>
                                    <p className="label">Right Ratio</p>
                                    <h3 className="font-bold">{product.rightRatio}</h3>
                                </div>
                                <div>
                                    <p className="label">Tenor</p>
                                    <h3 className="font-bold capitalize">{product.tenor} {product.tenorIn}</h3>
                                </div>
                                <div>
                                    <p className="label">Series</p>
                                    <h3 className="font-bold">{product.series}</h3>
                                </div>
                                <div>
                                    <p className="label">Open Date</p>
                                    <h3 className="font-bold">{product.offerOpenDate}</h3>
                                </div>
                                <div>
                                    <p className="label">Close Date</p>
                                    <h3 className="font-bold">{product.offerCloseDate}</h3>
                                </div>
                        </div>
                        <div className="mt-4">
                            <h1 className="label">Issuing Houses </h1>
                            {product.issuingHouses.map((house, index) => (
                                <span key={index} className="font-bold capitalize">{(product.issuingHouses.length > 1 && index !== 0) && ','} {house}</span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </FullscreenModalWrapper>}
        <EditProduct product={product} 
            onEditProduct={onUpdateProduct} 
            showEditProductModal={showEditProductModal}
            onCloseModal={onCloseEditProduct}
        />
        </div>
    );

    
}

export default SingleProduct
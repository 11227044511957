import { CircleUser, FileChartColumn, Files, House, Landmark, LogOut, Menu, MessageSquareDot, User, Users, UsersRound } from 'lucide-react';
import React, {useState, ReactNode} from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../logo';
import { Auth, useAuthStore } from '../../lib/auth/auth-store';

interface LayoutProps {
    children: ReactNode;
  }

const AuthLayout : React.FC <LayoutProps>= ({children}) => {
    const {handleLogout} = useAuthStore();
    
    const {name} = Auth();
    
    const [showNav, setShowNav] = useState<boolean>(false);

    const currentUrl = useLocation().pathname;

    const onSidebar =  () => {
        const sidebar = document.getElementById('sidebar');
        setShowNav(!showNav)
        setTimeout(() =>{
            sidebar?.classList.toggle('-translate-x-full');
            sidebar?.classList.toggle('translate-x-0'); 
        }, 1)  
    }

    return (
        <div className='flex-col min-h-screen'>
            <div className="relative flex w-full m-0 m-h-screen">
            {/* sidebar */}
            <div id='sidebar' className={`${showNav ? 'absolute w-3/5 z-50 h-full shadow' : 'hidden w-1/3 min-h-screen '} flex flex-col transform -translate-x-full md:translate-x-0 transition-transform duration-500 ease-in-out px-10 py-5 pb-20 lg:w-1/5 bg-black md:block text-white`}>
                <div className='relative flex items-center justify-center pb-5 mt-5 space-x-3 border-b border-gray-600'>
                    {/* <SvgLogo/> */}
                    <h1 className='text-primary font-[800]'>Admin Portal</h1>
                    <p  className='absolute right-0 flex items-center justify-center w-10 h-10 text-[10px] text-white rounded-full -top-2 md:hidden bg-tertiary' 
                        onClick={()=> onSidebar()}>
                        x
                    </p>
                </div>
                <menu className='mt-[2em] font-[400] text-[14px]'>
                    <div className='grid gap-y-5'>
                        <Link to='/' className={`${currentUrl === '/' && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><House size={18}/></div>
                            <h3>Dashboard</h3>
                        </Link>
                        <Link to='/members' className={`${currentUrl.includes('members') && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><UsersRound size={18}/></div>
                            <h3>Members</h3>
                        </Link>
                        <Link to='/products' className={`${currentUrl === '/products' && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><Files size={18}/></div>
                            <h3>Products</h3>
                        </Link>
                        <Link to='/transactions' className={`${currentUrl === '/transactions' && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><FileChartColumn size={18}/></div>
                            <h3>Transactions</h3>
                        </Link>
                        <Link to='/message' className={`${currentUrl.includes('message') && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><MessageSquareDot size={18}/></div>
                            <h3>Messaging</h3>
                        </Link>
                        <Link to='/payment' className={`${currentUrl === '/payment' && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><Landmark size={18}/></div>
                            <h3>Payment</h3>
                        </Link>
                        <Link to='/users' className={`${currentUrl === '/users' && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><Users size={18}/></div>
                            <h3>Users</h3>
                        </Link>
                        <Link to='/account' className={`${currentUrl === '/account' && 'font-[700] bg-primary'} flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><CircleUser size={18}/></div>
                            <h3>Account</h3>
                        </Link>
                        <button onClick={handleLogout} className={`flex space-x-3 py-3 hover:bg-primary hover:font-[700] rounded px-5`}>
                            <div><LogOut size={18}/></div>
                            <h3>Logout</h3>
                        </button>
                    </div>
                </menu>
            </div>
            <div className='w-full px-5 md:w-4/5 lg:px-[3em] py-7'>
                {/* header */}
                <div className='flex items-center justify-between pb-5 space-x-2 border-b border-gray-100'>
                    <div className='flex items-center space-x-3 md:hidden'>
                        <span onClick={()=> onSidebar()}>
                            <Menu size={18}/>
                        </span>
                            {/* <h1 className='text-primary'>Admin Portal</h1>   */}
                    </div>
                   <div className='font-[700]'>
                    <Logo/>
                   </div>
                    <Link to={'/account'} className='flex items-center justify-end space-x-10 w-fit'>
                        <div className='flex items-center space-x-2'>
                            <div className='flex items-center justify-center w-8 h-8 overflow-hidden bg-green-300 rounded-full'>
                                <User size={18}/>
                            </div>
                            <div className='flex items-center space-x-2'>
                                <h3 className='font-[700] text-tertiary text-[12px] capitalize'>{name}</h3>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='my-8'>
                {children}
                </div>
            </div>
        </div>
        </div>
    );
}

export default AuthLayout;
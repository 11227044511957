import { Check, X } from "lucide-react";
import React, {useState} from "react";

type ToggleSwitchType = {
    initialState:boolean,
    onToggle?: (state: boolean) => void | null,
    disableToggle?: boolean,
}
const ToggleSwitch = ({initialState, onToggle = () => {}, disableToggle = false}:ToggleSwitchType) => {


    const handleToggle = () => {
        if (disableToggle) return;
        const newState = !initialState;
        //setToggle(newState);
        onToggle(newState);
    }

    return (
        <div onClick={handleToggle} className={`relative grid ${!disableToggle && 'cursor-pointer'} content-center w-11 h-6 p-1 rounded-full ${initialState ? 'bg-primary justify-items-end' : 'bg-gray-300'}`}>
            <div className="grid p-1 bg-white rounded-full w-fit justify-items-center">
                {initialState ? <Check size={10} />  : <X size={10}/>}
            </div>
        </div>
    );
}

export default ToggleSwitch
import { Link } from "react-router-dom"
import AuthLayout from "../../../components/layouts/auth"
import { Eye } from "lucide-react"
import ApiRequest from "../../../lib/ApiCall"
import { useEffect, useState } from "react"
import PaginationType from "../../../types/pagination-type"
import TablePagination from "../../../components/table/table-pagination"

const Members = () => {

    type MembersType = {
        uuid:string,
        contactAddress:string,
        contactRepresentatives:[],
        secRegistrationNumber:string,
        status:string,
        nameInstitution: string,
        
    };
    
    const [members, setMembers] = useState<MembersType[]>([]);

    const [data, setData] = useState<PaginationType>({} as PaginationType);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const getMembers = async () => {
        const request = await ApiRequest({endpoint:`member?page=${currentPage}`, method:'GET'});
        if (request.status !== '200') return;
        setMembers(request.data.items);
        setData(request.data);
    }

    useEffect(() => {getMembers()}, [currentPage]);

    return (
        <AuthLayout>
            <div>
                <div>
                    <h1 className="page-title">Members</h1>
                    <p className="text-sm italic font-light text-gray-700">total members {data.total}</p>
                </div>
                <div className='relative overflow-x-auto'>
                    <table className='mt-5 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Institution Name</td>
                                <td className="px-6 py-3">SEC Reg. Number</td>
                                <td className="px-6 py-3 text-center">Number of representatives</td>
                                <td className="px-6 py-3 text-center">Status</td>
                                <td className="px-6 py-3 text-center">Action</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {members?.map((member, i) => (
                                <tr key={i} className='border-b border-[#E6EAF0]'>
                                <td className="px-4 py-3 capitalize">{member.nameInstitution}</td>
                                <td className="px-6 py-3">{member.secRegistrationNumber}</td>
                                <td className="px-6 py-3 text-center">{member.contactRepresentatives.length}</td>
                                <td className={`px-6 py-3 text-xs text-center uppercase ${member.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>{member.status}</td>
                                <td className="px-6 py-3 text-center">
                                    <Link to={`/members/${member.uuid}`} className="pryBtn">
                                        <span><Eye size={18}/></span>
                                        <span>Preview</span>
                                    </Link>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                 {/* pagination */}
                 <div className="mt-5">
                    <TablePagination
                        currentPage={currentPage}
                        totalPages={data.totalPages ?? 0}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </AuthLayout>
    )
}

export default Members
import React, { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, 
    Bold, Essentials, 
    Italic, Mention,
    Paragraph, Undo, 
    Link, Heading,
    List, HeadingButtonsUI, ParagraphButtonUI,
    Font,
} from 'ckeditor5';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import 'ckeditor5/ckeditor5.css';
import AuthLayout from '../../../components/layouts/auth';
import ApiRequest from '../../../lib/ApiCall';
import InputField from '../../../components/form/input-field';
import { EmptyFormInput } from '../../../lib/helper-support';
import { ErrorFlash, SuccessFlash } from '../../../lib/flash-notification';
import SpinnerLoader from '../../../components/loader/spinner-loader';
import { ChevronLeft } from 'lucide-react';

const PushMessage = () => {
  const editorRef = useRef<ClassicEditor | null>();
  const [data, setData] = React.useState<string | undefined>('');

  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  const formSchema = yup.object({
    subject: yup.string().required('Subject is required'),
    message: yup.string().required('Message is required'),
});

const  {
    setValue,
    setError,
    formState: {errors},
    handleSubmit,
    getValues,
    reset,
} = useForm({resolver: yupResolver(formSchema)}); 
  
  const sendNotification = async (data:any) => {
      //data['message'] = editorRef.current?.getData();
      setSubmitLoading(true);
      const request = await ApiRequest({endpoint: 'notify-members', method: 'POST', payload: data});
      setSubmitLoading(false);
      if (request.status !== '200') {
        ErrorFlash(request.message);
        return
      }
      setData('');
      reset(EmptyFormInput(formSchema));
      SuccessFlash('Notification sent successfully');
  }
  return (
    <AuthLayout>
        <form onSubmit={handleSubmit(sendNotification)}>
            <div className='flex items-center mb-5 space-x-2'>
                <RouterLink to={'/message'}><ChevronLeft/></RouterLink>
                <h1 className='page-title'>Send notification to all members</h1>
            </div>
            <div className='mb-5'>
                <InputField
                    error={errors.subject?.message}
                    onChangeInput={(e) => [setValue('subject', e.target.value), setError('subject', {message: ''})]}
                    value={getValues('subject')}
                    label='Subject'
                />
            </div>
        <CKEditor
            editor={ ClassicEditor }
            config={ {
                toolbar: {
                    items: ['undo', 'redo', 'heading',  
                            '|', 'bold', 'italic', 'link', 
                            'bulletedList', 'numberedList',
                            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
                    ],
                },
                plugins: [
                    Bold, Essentials, Italic, Mention, Paragraph, Undo, Link, Heading,
                    HeadingButtonsUI, ParagraphButtonUI, Font,
                ],
                heading: {
                    options: [
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
                    ]
                }
            } }
            onReady={ (editor) => {
                editorRef.current = editor;
            } }
            data={ getValues('message') || '' }
            onChange={ () => {
                setValue('message', (editorRef.current && editorRef.current?.getData()) || ''); 
                setError('message', {message: ''});
            } }
        />
                {errors.message  && <p className="my-1 text-sm text-red-500 label">{errors.message.message}</p>}
        <button onClick={sendNotification} disabled={submitLoading} className={`${submitLoading && 'opacity-[0.5]'} mt-5 pryBtn w-fit`}>
            Send notification {submitLoading && <SpinnerLoader/>}
        </button>
        </form>
    
    </AuthLayout>
  );
}

export default PushMessage
import AuthLayout from "../../../components/layouts/auth"
import SingleProduct from "./single-product"
import { useEffect, useState } from "react"
import ApiRequest from "../../../lib/ApiCall"
import PaginationType from "../../../types/pagination-type"
import { numberWithCommas } from "../../../lib/helper-support"
import { SuccessFlash } from "../../../lib/flash-notification"
import TablePagination from "../../../components/table/table-pagination";


const Products = () => {
    type ProductType = {
        status: string;
        issuer: string;
        type: string;
        amount: string|number,
        interestRate: string|number,
        series: string,
        offerOpenDate:string,
        offerCloseDate:string,
        issuingHouses:string[],
        tenor: string,
        tenorIn: string,  
        amountCurrency: string,
        postedBy: string,
        uuid: string,
    }
    
    const [products, setProducts] = useState<ProductType[]>([]);

    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const getProducts = async () => {
        const request = await ApiRequest({endpoint:`product?page=${currentPage}`, method:'GET'});
        if (request.status !== '200') return;
        setProducts(request.data.items);
        setPageData(request.data);
    }
    

    useEffect(() => {getProducts()}, [currentPage]);

    const onProductUpdate =  (product:any) => {
        setProducts(products.map((item) => item.uuid === product.uuid ? {...product} : item));   
    }
    const onProductDelete =  (product:any) => {
        setProducts(products.filter((item) => item.uuid !== product.uuid));
        setPageData({...pageData, total: pageData.total - 1});
        SuccessFlash('Product deleted successfully')
    }
    return (
        <AuthLayout>
            <div>
                <div>
                    <h1 className="page-title">Products</h1>
                    <p className="text-sm italic font-light text-gray-700">total products {pageData.total}</p>
                </div>
                <div className='relative overflow-x-auto'>
                    <table className='mt-5 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Posted By</td>
                                <td className="px-4 py-3">Issuer</td>
                                <td className="px-6 py-3">Type</td>
                                <td className="px-6 py-3">Amount</td>
                                <td className="px-6 py-3 text-center">Interest Rate</td>
                                <td className="px-6 py-3">Tenor</td>
                                <td className="px-6 py-3">Status</td>
                                <td className="px-6 py-3 text-center">Action</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {products.map((product, i) => (
                                <tr key={i} className='border-b border-[#E6EAF0]'>
                                    <td className="px-4 py-3 capitalize">{product.postedBy}</td>
                                    <td className="px-4 py-3">{product.issuer}</td>
                                    <td className="px-6 py-3">{product.type}</td>
                                    <td className="px-6 py-3">{product.amountCurrency} {numberWithCommas(product.amount)}</td>
                                    <td className="px-6 py-3 text-center">{product.interestRate}</td>
                                    <td className="px-6 py-3 capitalize">{product.tenor} {product.tenorIn}</td>
                                    <td className={`px-6 py-3 text-xs uppercase ${product.status === 'published' ? 'text-green-500' : 'text-yellow-500'}`}>{product.status}</td>
                                    <td className="px-6 py-3 text-center">
                                        {<SingleProduct productId={product.uuid} onProductUpdate={onProductUpdate} onProductDelete={onProductDelete}/>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                 {/* pagination */}
                 <div className="mt-5">
                    <TablePagination
                        currentPage={currentPage}
                        totalPages={pageData.totalPages ?? 0}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </AuthLayout>
    )
}

export default Products

type onChangeInputFunc = (e:React.ChangeEvent<HTMLSelectElement>) => void

type OptionType = {
    name: string,
    value: string,
}

type InputType = {
    placeholder?:any,
    label?: string,
    labelClass?: string,
    id?: string,
    value?:string,
    error?:string|null,
    disabled?:boolean,
    onChangeInput?: onChangeInputFunc,
    inputClass?:string,
    options?: OptionType[],
}

const SelectField = ({
    placeholder,
    label,
    labelClass,
    id,
    value,
    error,
    onChangeInput,
    disabled = false,
    inputClass,
    options
} : InputType) => {
    const Id = id ?? label;

    return(
        <label htmlFor={Id} className="label">
            <span className={`${labelClass}`}>{label}</span>
            <select 
                className={`input border-[#E8ECEF] ${inputClass} border ${error && 'border-red-500'} ${disabled && 'text-light-black'} text-light-black`}
                onChange={onChangeInput}
                value={value}
                disabled={disabled}
            >
                <option value=''>{placeholder ?? `Select ${label}`}</option>
                {options?.map((option) => <option value={option.value} key={option.name}>{option.name}</option>)}
            </select>
            {error && <p className="my-1 text-[13px] text-red-500 capitalize label font-[400]">{error}</p>}
        </label>
    );
}

export default SelectField;
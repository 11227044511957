import { useEffect, useState } from "react";
import AuthLayout from "../../components/layouts/auth";
import ApiRequest from "../../lib/ApiCall";
import InputField from "../../components/form/input-field";
import SpinnerLoader from "../../components/loader/spinner-loader";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { EmptyFormInput, SmoothScroll } from "../../lib/helper-support"
import { ErrorFlash, SuccessFlash } from "../../lib/flash-notification"
import { useAuthStore } from "../../lib/auth/auth-store";

const Account = () => {

    interface AccountType {
        email: string,
        name: string,
    }

    const {handleLogout} = useAuthStore();

    const [account, setAccount] = useState<AccountType>({} as AccountType);
    
    const getAccount = async () => {
        const request = await ApiRequest({endpoint: '/profile', method:'GET'});
        if (request.status !== '200') return;
        setAccount(request.data);
    }

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const formSchema = yup.object({
        currentPassword: yup.string().required('Current password is required'),
        password: yup.string().required('New password is required'),
        passwordConfirmation: yup.string().required('New password confirmation is required'),
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues,
        reset,
    } = useForm({resolver: yupResolver(formSchema)}); 

    const onSubmit = async (data:any) => {
        setLoadingSubmit(true)
        const request = await ApiRequest({endpoint: '/profile/change-password', method:'POST', payload: data});
        setLoadingSubmit(false)
        reset(EmptyFormInput(formSchema));
        if ( request.status === '200') {
            SuccessFlash("Password changed successfully");
            SuccessFlash("You'll be redirected to login page", 5);
            setTimeout(() => handleLogout(), 3000);
            return;
        }

        ErrorFlash(request.message);

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
                SmoothScroll();
            }
          }
    }

    useEffect(() => {getAccount()}, []);
    return(
        <AuthLayout>
            <div>
                <div>
                    <h1 className="page-title">Account Settings</h1>
                </div>
                <div className="mt-10 ">
                    <div className="grid gap-4">
                        <div>
                            <label className="label">Name</label>
                            <p className="font-bold capitalize">{account.name}</p>
                        </div>
                        <div>
                            <label className="label">Email</label>
                            <p className="font-bold lowercase">{account.email}</p>
                        </div>
                    </div>
                    <h3 className="mt-10 font-bold label">Change Password</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className="grid gap-4 mt-5 lg:w-1/2">
                        <InputField
                            label="Current Password"
                            type="password"
                            error={errors.currentPassword?.message}
                            onChangeInput={(e) => [setValue('currentPassword', e.target.value), setError('currentPassword', {message: ''})]}
                            value={getValues('currentPassword')}
                        />
                        <InputField
                            label="New Password"
                            type="password"
                            error={errors.password?.message}
                            onChangeInput={(e) => [setValue('password', e.target.value), setError('password', {message: ''})]}
                            value={getValues('password')}
                        />
                        <InputField
                            label="Confirm New Password"
                            type="password"
                            placeholder="Confirm New Password"
                            error={errors.passwordConfirmation?.message}
                            onChangeInput={(e) => [setValue('passwordConfirmation', e.target.value), setError('passwordConfirmation', {message: ''})]}
                            value={getValues('passwordConfirmation')}
                        />
                        <button disabled={loadingSubmit} className={`px-10 pryBtn w-fit ${loadingSubmit && 'opacity-[0.5]'}`}>
                            Submit {loadingSubmit && <SpinnerLoader/>}
                        </button>
                    </form>
                </div>
            </div>
        </AuthLayout>
    );
}

export default Account;
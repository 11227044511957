import { Minus } from "lucide-react";
import AuthLayout from "../../../components/layouts/auth"
import ApiRequest from "../../../lib/ApiCall";
import { getCurrency, numberWithCommas } from "../../../lib/helper-support";
import PaginationType from "../../../types/pagination-type";
import { useState, useEffect } from "react";
import moment from "moment";
import TablePagination from "../../../components/table/table-pagination";

const Transactions = () => {
    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);

    const [currentPage, setCurrentPage] = useState<number>(1);

    interface TransactionType {
        uuid: string
        issuer: string
        type: string
        security: string
        series: string
        transactionSize: string
        regulatorQuasi: string
        transactionSizeCurrency: string
        createdAt: string
        role: string
        completionIssueDate:string,
        postedBy:string,
    }

    const [transactions, setTransactions] = useState<TransactionType[]>([]);

    const getTransactions = async () => {
        const request = await ApiRequest({endpoint: `transactions?page=${currentPage}`, method: 'GET'});
        if (request.status !== '200') return;
        setPageData(request.data);
        setTransactions(request.data.items)
    }

    useEffect(() => {
        getTransactions();
    }, [currentPage]);
    
    return (
        <AuthLayout>
            <div>
                <div>
                    <h1 className="page-title">Transactions</h1>
                    <p className="text-sm italic font-light text-gray-700">total transactions {pageData.total}</p>
                </div>
                <div className='relative overflow-x-auto'>
                    <table className='mt-5 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Posted By</td>
                                <td className="px-3 py-3">Client/Issuer</td>
                                <td className="px-6 py-3">Type</td>
                                <td className="px-6 py-3">Security</td>
                                <td className="px-6 py-3 text-center">Series</td>
                                <td className="px-6 py-3">Size</td>
                                <td className="px-6 py-3">Regulator</td>
                                <td className="px-6 py-3">Date</td>
                                <td className="px-6 py-3">Role</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                        {transactions.map((transaction, i) => (
                            <tr key={i} className='border-b border-[#E6EAF0]'>
                                <td className="px-3 py-3 capitalize">{transaction.postedBy}​</td>
                                <td className="px-3 py-3">{transaction.issuer}​</td>
                                <td className="px-6 py-3">{transaction.type}</td>
                                <td className="px-6 py-3">{transaction.security ?? <Minus size={18}/>}</td>
                                <td className="px-6 py-3 text-center">{transaction.series ?? <Minus size={18}/>}</td>
                                <td className="px-6 py-3">
                                {getCurrency(transaction.transactionSizeCurrency)}{numberWithCommas(transaction.transactionSize)}
                                </td>
                                <td className="px-6 py-3">{transaction.regulatorQuasi ?? <Minus size={18}/>}</td>
                                <td className="px-6 py-3">{transaction.completionIssueDate ? moment(transaction.completionIssueDate).format('DD MMM YYYY') : <Minus size={18}/>}</td>
                                <td className="px-6 py-3 capitalize">{transaction.role ?? <Minus size={18}/>}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {/* pagination */}
                <div className="mt-5">
                    <TablePagination
                        currentPage={currentPage}
                        totalPages={pageData.totalPages ?? 0}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </AuthLayout>
    )
}

export default Transactions
import { Link } from "react-router-dom"
import InputField from "../../components/form/input-field"
import GuestLayout from "../../components/layouts/guest"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from "react";
import { useAuthStore } from "../../lib/auth/auth-store";
import ApiRequest from "../../lib/ApiCall";
import { SmoothScroll } from "../../lib/helper-support";

const Login = () => {

    const {handleLogin} = useAuthStore();
    
    const [loading, setLoading] = useState<boolean>(false);
    
    const Login = async (data:any) => {
        setLoading(true);
        const request = await ApiRequest({endpoint: 'auth/login', method: 'POST', payload: data});
        setLoading(false);

        if ( request.status === '200') {
            handleLogin(request.data);
            return;
        }

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
                SmoothScroll();
            }
        }

        if (request.status === "300") {
            setError('email', {type: "manual", message: request.message});
            setValue('password', '');
        }
    }

    const formSchema = yup.object({
        email: yup.string().email().required(),
        password: yup.string().required()
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues
    } = useForm({resolver: yupResolver(formSchema)}); 

    return (
        <GuestLayout>
            <div>
                <div>
                    <h1 className="page-title">Login</h1>
                    <p className="mt-2 text-gray-600 font-[400] label">Sign in with your email address and password</p>
                </div>
                <form onSubmit={handleSubmit(Login)} className="grid gap-8 mt-8">
                    <InputField
                        type="email"
                        label="Email Address"
                        error={errors.email?.message}
                        onChangeInput={(e) => [setValue('email', e.target.value), setError('email', {message: ''})]}
                        value={getValues('email')}
                    />
                    <InputField
                        type="password"
                        label="Password"
                        error={errors.password?.message}
                        onChangeInput={(e) => [setValue('password', e.target.value), setError('password', {message: ''})]}
                        value={getValues('password')}
                    />
                    <div>
                        <Link to={'/forgot-password'} className="label text-primary">Reset Password</Link>
                    </div>
                    <button disabled={loading} className={`pryBtn ${loading && 'opacity-[0.5]'}`}>Sign In</button>
                </form>
            </div>
        </GuestLayout>
    )
}

export default Login
import { useState, useEffect } from "react";
import ApiRequest from "../../../../../../lib/ApiCall";
import moment from "moment";
import { numberWithCommas } from "../../../../../../lib/helper-support";
import TablePagination from "../../../../../../components/table/table-pagination"; 
import PaginationType from "../../../../../../types/pagination-type";

interface PageType {
    fetchData: boolean,
}
const PaymentHistory = ({fetchData} : PageType) => {
    interface PaymentType{
        reference: string
        name:string,
        description:string,
        amount:string,
        paymentType:string,
        startAt:string,
        paymentTypeId:string,
        dueAmount:string,
        memberName: string,
    }

    const [payments, setPayments] = useState<PaymentType[]>([]);

    const [data, setPageData] = useState<PaginationType>({} as PaginationType);


    const [currentPage, setCurrentPage] = useState<number>(1);
    
    const getPayments = async () => {
        const request = await ApiRequest({endpoint: `payments?page=${currentPage}`, method: 'GET'}); 
        if (request.status !== '200') return;
        setPayments(request.data.items);
        setPageData(request.data);
    }
    useEffect(()=>{getPayments()}, [currentPage]);
    return (
        <div>
            <div className="mt-5 text-black p-10 rounded-[1em] border bg-gray-50 w-fit">
                        <span className="font-light">Total Payments</span>
                        <h3 className="font-[900] text-[1.5em]">{data.total}</h3>
                    </div>
            <div className='relative mt-5 overflow-x-auto'>
                    <table className='mt-1 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">Date</td>
                                <td className="px-4 py-3">Member</td>
                                <td className="px-6 py-3">Type</td>
                                <td className="px-6 py-3">Amount(NGN)</td>
                            </tr>
                        </thead>
                        
                        <tbody className="relative">
                        {payments.map((payment, i) => (
                            <tr key={i} className='border-b border-[#E6EAF0]'>
                                <td className="px-3 py-3">{moment(payment.startAt).format('DD MMM, YYYY')}</td>
                                <td className="px-6 py-3 capitalize">
                                    {payment.memberName}
                                </td>
                                <td className="px-6 py-3">{payment.name}</td>
                                <td className="px-6 py-3">{numberWithCommas(payment.amount)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                 {/* pagination */}
                 <div className="mt-5">
                    <TablePagination
                        currentPage={currentPage}
                        totalPages={data.totalPages ?? 0}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
        </div>
    );
}

export default PaymentHistory
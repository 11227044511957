import { ChevronLeft, Minus, UserRoundPlus } from "lucide-react"
import AuthLayout from "../../../components/layouts/auth"
import { Link, useParams } from "react-router-dom"
import InputField from "../../../components/form/input-field"
import RadioField from "../../../components/form/radio-field"
import TextareaField from "../../../components/form/textarea"
import {useEffect, useState } from "react"
import ToggleSwitch from "../../../components/form/toggle-switch"
import ApiRequest from "../../../lib/ApiCall"
import NotFound from "../../../components/not-found"
import { get, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { SmoothScroll } from "../../../lib/helper-support"
import SpinnerLoader from "../../../components/loader/spinner-loader"
import { ErrorFlash, SuccessFlash } from "../../../lib/flash-notification"
import Tooltip from "../../../components/tooltip"

const EditMember = () => {
    const {id} = useParams<{id:string}>();
    
    type MemberType = {
        uuid:string,
        contactRepresentatives: representativesType[],
        secRegistrationNumber:string,
        status:string,
        nameInstitution: string,
        registeredAddress: string,
        contactAddress: string,
        
    };

    const [member, setMember] = useState<MemberType>({} as MemberType);

    const [pageLoading, setPageLoading] = useState(true);

    const getMember = async () => {
        const request = await ApiRequest({endpoint:`/member/${id}`, method:'GET'});
        setPageLoading(false);
        if (request.status !== '200') return;
        setMember(request.data);
        reset(request.data);
        setSameAddress(request.data.sameAddress);
        setRepresentatives(request.data.contactRepresentatives);
    }

    useEffect(() => {getMember()}, []);
    
    type representativesType = {
        name: string,
        position: string
        mobile_number:string,
        email_address:string,
        is_primary: boolean
    }
    const [representatives, setRepresentatives] = useState<representativesType[]>([{} as representativesType]);
    const [sameAddress, setSameAddress] = useState<boolean>(false);

    const removeRepresentative = (index:number) => {
        const newRepresentatives = [...representatives];
        newRepresentatives.splice(index, 1);
        setRepresentatives(newRepresentatives);
    };

    const onChangeRepresentativeInput = (index:number, field:string, e:React.ChangeEvent<HTMLInputElement>) => {
        const newRepresentatives = representatives.map((rep, i) => {
            if (i === index) {
                const target = e.target as HTMLInputElement | HTMLTextAreaElement;
                return { ...rep, [field]: target.value };
            }
            return rep;
        });
        setRepresentatives(newRepresentatives);
    };

    const onPrimaryContact = (index:number) => {
        const newRepresentatives = representatives.map((rep, i) => {
            return { ...rep, is_primary: i === index };
        });
        setRepresentatives(newRepresentatives);
    }

    const formSchema = yup.object({
        nameInstitution: yup.string(),
        registeredAddress: yup.string().required('Registered address is required'),
        contactAddress: yup.string().required('Contact address is required'),
        secRegistrationNumber: yup.string(),
        contactRepresentatives: yup.array().of(
            yup.object({
                name: yup.string(),
                position: yup.string(),
                mobile_number: yup.string(),
                email_address: yup.string(),
                is_primary: yup.boolean()
            })
        )
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues,
        reset,
        clearErrors,
    } = useForm({resolver: yupResolver(formSchema)}); 

    const onRegisteredAddress = (value:string) => {
        sameAddress && setValue('contactAddress', value);
    }

    const onSameAddress = (state:boolean) => {
        setSameAddress(state);
        setValue('contactAddress', getValues('registeredAddress'));
    }

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const updateMember = async (data:any) => {
        data['contactRepresentatives'] = representatives;
        
        data['memberId'] = id;
        
        setLoadingSubmit(true);
        
        const request = await ApiRequest({endpoint: 'member/update', method: 'POST', payload: data});
        
        setLoadingSubmit(false);
        
        if ( request.status === '200') {
            setMember(request.data);
            
            clearErrors();
            SuccessFlash('Member updated successfully');
            return;
        }
        
        ErrorFlash(request.message);
    
        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
            }
        }
        
    }

    return (
        <AuthLayout>
            <div>
                {(! pageLoading && ! member.uuid) && <NotFound route="/members"/>}
                {member.uuid && <div>
                    <form onSubmit={handleSubmit(updateMember)}>
                    <div className="items-center justify-between space-y-5 lg:flex lg:space-y-0">
                    <div className="flex items-center space-x-2">
                        <Link to={`/members/${id}`}><ChevronLeft/></Link>
                        <h1 className="text-sm page-title">Edit: {member.nameInstitution}</h1>
                    </div>
                    <button disabled={loadingSubmit} className={`px-10 pryBtn w-fit ${loadingSubmit && 'opacity-[0.5]'}`}>
                        <span>Update member {loadingSubmit && <SpinnerLoader/>}</span>
                    </button>
                </div>
                <div className="mt-10">
                    <div>
                        <div className="grid gap-4 lg:grid-cols-2">
                            <div>
                                <InputField
                                    label="Institution Name"
                                    onChangeInput={() => {}}
                                    value={getValues('nameInstitution')}
                                    disabled={true}
                                    inputClass="capitalize"
                                    
                                />
                            </div>
                            <div>
                                <InputField
                                    label="SEC Registration Number"
                                    onChangeInput={() => {}}
                                    value={getValues('secRegistrationNumber')}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <TextareaField
                                label='Registered Address'
                                placeholder='Enter full address'
                                error={errors.registeredAddress?.message}
                                onChangeInput={(e) => [setValue('registeredAddress', e.target.value), onRegisteredAddress(e.target.value), setError('registeredAddress', {message: ''})]}
                                value={getValues('registeredAddress')}
                            />
                        </div>
                        <div className="flex mt-4 space-x-3">
                            <ToggleSwitch
                                initialState={sameAddress}
                                onToggle={(state) => onSameAddress(state)}
                            />
                            <span className="label">Contact Address is the same as above</span>
                        </div>
                        <div className={`${sameAddress ? 'hidden' : ''} mb-10 mt-4`}><TextareaField
                            label='Contact Address'
                            placeholder='Enter full address'
                            error={errors.contactAddress?.message}
                            value={getValues('contactAddress')}
                            onChangeInput={(e) => [setValue('contactAddress', e.target.value), setError('contactAddress', {message: ''})]}
                        /></div>
                        <div className="mt-4">
                            <p className="font-bold label text-secondary">Contact details of Representatives</p>
                        </div>
                        {representatives.map((rep, index) => (
                            <div key={index} className="mt-4">
                                <p className="font-bold label">
                                    Contact {index + 1}: 
                                    {index > 1 && (
                                        <span onClick={() => removeRepresentative(index)} className="cursor-pointer text-red-800 text-sm flex items-center space-x-1 font-[400] hover:underline w-fit">
                                            <Minus size={18}/>
                                            <span>Remove Representative</span>
                                        </span>
                                    )}
                                </p>
                                <div className="grid gap-4 mt-1 lg:grid-cols-2">
                                    <div>
                                        <InputField
                                            label="Name"
                                            onChangeInput={(e) => [onChangeRepresentativeInput(index, 'name', e), setError('contactRepresentatives', {message: ''})]}
                                            value={rep.name}
                                            placeholder="Enter first and last name"
                                            error={errors.contactRepresentatives?.[index]?.name?.message} 
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Position"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'position', e)}
                                            value={rep.position}
                                            placeholder="Enter job title/position"
                                            error={errors.contactRepresentatives?.[index]?.position?.message} 
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Mobile number"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'mobile_number', e)}
                                            type="number"
                                            value={rep.mobile_number}
                                            placeholder="E.g. +2348031231234"
                                            error={errors.contactRepresentatives?.[index]?.mobile_number?.message} 
                                        />
                                    </div>
                                    <div>
                                        <InputField
                                            label="Email address"
                                            onChangeInput={(e) => onChangeRepresentativeInput(index, 'email_address', e)}
                                            type="email"
                                            value={rep.email_address}
                                            placeholder="name@company.com"
                                            error={errors.contactRepresentatives?.[index]?.email_address?.message} 
                                        />
                                    </div>
                                    <div className="flex items-center space-x-3">
                                        <RadioField
                                            name="primary_contact"
                                            onChangeInput={() => onPrimaryContact(index)}
                                            label="Set as primary contact"
                                            checked={rep.is_primary}
                                            value={index.toString()}
                                        />
                                        <Tooltip text="The portal account will be created with the email address of the primary contact"/>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="mt-4">
                            <button type="button" onClick={() => setRepresentatives([...representatives, {} as representativesType])} className={`pryBtnOutline w-fit`}>
                                <UserRoundPlus size={18}/>
                                <span>Add new representative</span>
                            </button>
                        </div>
                    </div>
                </div>
                    </form>
            </div>}
            </div>
        </AuthLayout>
    )
}

export default EditMember
import React from "react";

type InputType = {
    label?: string,
    id?: string,
    value?:string,
    error?:string|null,
    name: string,
    checked?:boolean,
    onChangeInput: (state: boolean) => void | null,
}

const RadioField = ({
    label,
    id,
    value,
    error,
    name,
    checked = false,
    onChangeInput
} : InputType) => {
    const Id = `${id ?? value}_radio`;

    const handleToggle = (e:React.ChangeEvent<HTMLInputElement>) => {
        onChangeInput(e.target.checked);
    }

    return(
        <label htmlFor={Id} className="label space-x-2 items-center flex cursor-pointer w-fit">
            <span>{label}</span>
            <input 
                name={name}
                type={'radio'}
                onChange={handleToggle}
                value={value}
                checked={checked}
                id={Id}
            />
            {error && <span className="text-red-500 label">{error}</span>}
        </label>
    );
}

export default RadioField;
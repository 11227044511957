import AuthLayout from "../../../components/layouts/auth";
import moment from "moment";
import TablePagination from "../../../components/table/table-pagination";
import { useState, useEffect } from "react";
import PaginationType from "../../../types/pagination-type";
import { Link } from "react-router-dom";
import ApiRequest from "../../../lib/ApiCall";
import SingleMessage from "./single-message";
const SentMessages = () => {
    const [pageData, setPageData] = useState<PaginationType>({} as PaginationType);

    const [currentPage, setCurrentPage] = useState<number>(1);

    interface MessageType{
        uuid: string,
        subject: string,
        content: string,
        createdAt: string,
    }

    const [messages, setMessages] = useState<MessageType[]>([]);

    const getMessages = async () => {
        const request = await ApiRequest({endpoint: `noticeMessage?page=${currentPage}`, method: 'GET'});
        if (request.status !== '200') return;
        setPageData(request.data);
        setMessages(request.data.items)
    }

    useEffect(() => {
        getMessages();
    }, [currentPage]);

    const getShortContent = (content:string) => {
        return content.length > 50 ? `${content.slice(0, 50)}...` : content;
    }

    return (
        <AuthLayout>
            <div>
                <div>
                    <h1 className="page-title">Messages</h1>
                    <p className="text-sm italic font-light text-gray-700">total messages {pageData.total ?? 0}</p>
                </div>
                <Link to={'/send-message'} className="mt-5 pryBtn w-fit">Send new message</Link>
                <div className='relative overflow-x-auto'>
                    <table className='mt-5 text-tertiary font-[500] text-[14px] w-full relative z-10'>
                        <thead>
                            <tr className='border-b border-[#E6EAF0] bg-[#fafafa]'>
                                <td className="px-4 py-3">S/N</td>
                                <td className="px-3 py-3">Subject</td>
                                <td className="px-6 py-3">Content</td>
                                <td className="px-6 py-3">Date Sent</td>
                                <td className="px-6 py-3">Action</td>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {messages.map((message, i) => (
                                <tr key={i} className='border-b border-[#E6EAF0]'>
                                    <td className="px-4 py-3">{i+1}​</td>
                                    <td className="px-3 py-3">{message.subject}​</td>
                                    <td className="px-6 py-3">
                                        <div dangerouslySetInnerHTML={{__html: getShortContent(message.content)}}></div>
                                    ​</td>
                                    <td className="px-6 py-3">{moment(message.createdAt).format('DD MMM YYYY')}​</td>
                                    <td className="px-6 py-3"><SingleMessage message={message}/></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* pagination */}
                <div className="mt-5">
                    <TablePagination
                        currentPage={currentPage}
                        totalPages={pageData.totalPages ?? 0}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            </div>
        </AuthLayout>
    );
}

export default SentMessages
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { AuthToken } from "./auth/auth-store";

axios.defaults.baseURL = '/api';//process.env.REACT_APP_API_BASE_URL

const token = AuthToken();

type RequestType = {
  endpoint: string;
  method: "GET" | "POST";
  payload?: any;
};

type ResponseType = {
  data?: any;
  status: string;
  message: string;
};

const ApiRequest = async ({endpoint,method,payload = []} : RequestType) : Promise<ResponseType> => {
    let headers : any = {
        'Content-Type': 'application/json',
        accept: "*/*",
      };

      token && (headers['Authorization'] = `Bearer ${token}`);

      const params: AxiosRequestConfig = {
        method,
        url: endpoint,
        data: payload,
        headers,
      };

      let response: AxiosResponse<ResponseType>;

      try {
        axiosRetry(axios, { retries: 1 });
        response = await axios.request<ResponseType>(params);
      } catch (e) {
        return {
          status: "error",
          message: "Error processing request, try again.",
        };
      }
  
      return response.data;
}

export default ApiRequest;
import { Link } from "react-router-dom"
import InputField from "../../components/form/input-field"
import GuestLayout from "../../components/layouts/guest"
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from "react";
import ApiRequest from "../../lib/ApiCall";
import { EmptyFormInput } from "../../lib/helper-support";

const ForgotPassword = () => {

    const [loading, setLoading] = useState<boolean>(false);
    
    const [processState, setProcessState] = useState<string|null>(null);
    
    const [message, setMessage] = useState<string>('');

    const formSchema = yup.object({
        email: yup.string().email().required('Email is required'),
    });

    const  {
        setValue,
        setError,
        formState: {errors},
        handleSubmit,
        getValues,
        reset,
    } = useForm({resolver: yupResolver(formSchema)}); 


    const forgotPassword = async (data:any) => {
        setLoading(true);
        
        const request :any = await ApiRequest({endpoint: 'auth/forgot-password', method: 'POST', payload: data});
        
        setLoading(false);
        
        if ( request.status === '200'){
            reset(EmptyFormInput(formSchema));
            setProcessState('success');
            setMessage('Password reset link has been sent to your email');
            return;
        }

        setProcessState('error');
        
        setMessage(request.message);

        if (request.status === "payloadValidationError") {
            for (const [field, messages] of Object.entries(request.data)) {
                const input_field : any = field ;
                const error_message : any = messages
                setError(input_field, { type: "manual", message: error_message.join(", ") });
            }
        }
    }

    return (
        <GuestLayout>
            <form onSubmit={handleSubmit(forgotPassword)}>
                <div>
                    <h1 className="page-title">Reset your password</h1>
                    <p className="mt-2 text-gray-600 font-[400] label">Provide the email on your account and we’ll send details to reset your password</p>
                    {processState && <div className={`${processState === 'success' ? 'text-green-500' : 'text-red-500'} text-[14px] my-2`}>{message}</div>}
                </div>
                <div className="grid gap-8 mt-8">
                    <InputField
                        type="email"
                        label="Email Address"
                        error={errors.email?.message}
                        value={getValues('email')}
                        onChangeInput={(e) => [setValue('email', e.target.value), setError('email', {message: ''})]}
                    />
                    <button disabled={loading} className={`pryBtn ${loading && 'opacity-[0.5]'}`}>Reset Password</button>
                    <div>
                        <p className="flex space-x-2 label">
                            <span>Remembered your password?</span>
                            <Link to={'/login'} className="text-primary">Return to Login</Link>
                        </p>
                    </div>
                </div>
            </form>
        </GuestLayout>
    )
}

export default ForgotPassword
import { useState } from "react";
import AuthLayout from "../../../components/layouts/auth";
import PaymentHistory from "./partials/page/payment-history/payment-history";
import PaymentType from "./partials/page/payment-types/payment-type";

const Payments = () => {
    const [currentTab, setCurrentTab] = useState<string>('history');

    const [fetchHistoryData, setFetchHistoryData] = useState<boolean>(true);

    const [fetchTypeData, setFetchTypeData] = useState<boolean>(false);

    const onChangeTab = (tab: string) => {
        setCurrentTab(tab);
        setFetchHistoryData(tab === 'history');
        setFetchTypeData(tab === 'type');
    }

    return(
        <AuthLayout>
            <div>
                <h1 className="page-title">Payments</h1>
                <div className="flex items-center p-1 mt-10 space-x-10 border-b font-[450] text-gray-600">
                    <div onClick={() => onChangeTab('history')} className={`${currentTab === 'history' && ' text-primary'} cursor-pointer`}>Payment Histories</div>
                    <div onClick={() => onChangeTab('type')} className={`${currentTab === 'type' && ' text-primary'} cursor-pointer`}>Payment Types</div>
                </div>
                <div className="mt-2">
                    {fetchHistoryData && <PaymentHistory fetchData={fetchHistoryData}/>}
                    {fetchTypeData && <PaymentType fetchData={fetchTypeData}/>}
                </div>
            </div>
        </AuthLayout>
    );
}

export default Payments
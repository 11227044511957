import {create} from "zustand";
import {persist, createJSONStorage} from "zustand/middleware";
import { decrypt, encrypt } from "../helper-support";

interface MemberState  {
  name: string,
  memberId: string,
};

type UserState = {
    token: string,
    name: string,
    email: string,
    member: MemberState,
    role: string,
}

type AuthState = {
    user: string | null,
    handleLogin: (data:UserState) => void,
    handleLogout: () => void,
}

export const useAuthStore = create(
    persist<AuthState>(
      (set) => ({
        user: null,
        handleLogin: (data: UserState) => {
            set({ user: encrypt(JSON.stringify(data)) });
            window.location.reload();
        },
        handleLogout: () => {
            set({ user: null } );
            useAuthStore.persist.clearStorage();
            window.location.reload();
        },
      }),
      {
        name: 'auth',
        storage: createJSONStorage(() => localStorage),
      }
    )
  );

  export const Auth = () : UserState  => {
    return JSON.parse(decrypt(useAuthStore.getState().user ?? ''));
  }

  export const AuthToken = () : string | null => {
    const logged = Boolean(!! useAuthStore.getState().user);
    return logged ? Auth().token : null;
  }
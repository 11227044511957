import { Link } from "react-router-dom";
import { CircleAlert} from "lucide-react"

type NotFoundType = {
    route?: string,
};
const NotFound = ({route} : NotFoundType) => {
    return (
        <div>
            <div className="flex flex-col items-center mt-[10em] space-y-5 text-yellow-800">
                <p className="text-center "><CircleAlert size={40}/></p>
                <h1 className="">What you are looking for does not exists.</h1>
                {route && <Link to={route} className="flex pryBtn w-fit">Go back</Link>}
            </div>
        </div>
    );
}

export default NotFound;
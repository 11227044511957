import { CircleX, Eye } from "lucide-react";
import { useState } from "react";
import FullscreenModalWrapper from "../../../components/modal/wrapper/fullscreen-modal-wrapper";

interface MessageType{
    uuid: string,
    subject: string,
    content: string,
    createdAt: string,
}
interface SingleMessageType{
    message: MessageType
}
const SingleMessage = ({message} : SingleMessageType) => {
    const [showProductModal, setShowProductModal] = useState<boolean>(false);
    return (
        <div>
            <div onClick={() => setShowProductModal(true)} className="justify-center px-1 space-x-2 text-xs cursor-pointer pryBtn">
                <span><Eye size={18}/></span>
                <span>Preview</span>
            </div>
            {showProductModal && <FullscreenModalWrapper>
                <div className="relative">
                <button onClick={()=>{setShowProductModal(false)}} className="absolute top-3 right-5" type="button">
                    <CircleX size={30}/>
                </button>
                <div className="grid gap-3 py-5">
                    <div>
                        <h3 className="label">Subject</h3>
                        <p className="font-bold">{message.subject}</p>
                    </div>
                    <div>
                        <h4 className="label">Content</h4>
                        <div dangerouslySetInnerHTML={{__html: message.content}}></div>
                    </div>
                </div>  
                </div>  
            </FullscreenModalWrapper>}
        </div>
    )
}

export default SingleMessage
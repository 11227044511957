import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { numberWithCommas, removeCommas } from "../../lib/helper-support";
import Tooltip from "../tooltip";

type onChangeInputFunc = (e:React.ChangeEvent<HTMLInputElement>) => void

type InputTypeLabel = 'text' | 'email' | 'number' | 'password' | 'date' | 'money-format';

type InputType = {
    placeholder?:string,
    type?: InputTypeLabel,
    label?: string,
    id?: string,
    value?:string,
    error?:string|null,
    disabled?:boolean,
    onChangeInput?: onChangeInputFunc,
    inputClass?:string,
    toolTip?:string,
}

const InputField = ({
    placeholder,
    type = 'text',
    label,
    id,
    value,
    error,
    onChangeInput,
    disabled = false,
    inputClass,
    toolTip,
} : InputType) => {
    const Id = id ?? label;

    const getType = (type:InputTypeLabel)  => {
        if (type === 'money-format') return 'text';
        return type;
    }

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [inputType, setInputType] = useState<InputTypeLabel>(getType(type));

    const onTogglePassword = () => {
        setShowPassword(!showPassword)
        showPassword ? setInputType('password') : setInputType('text');
    }

    const onInput = (e:React.ChangeEvent<HTMLInputElement>) => {
        if (type === 'money-format') {
            const value = removeCommas(e.target.value);
            e.target.value = numberWithCommas(value);
        }
        onChangeInput && onChangeInput(e);
    }

    const formatValue = (value?:string) => {
        if (type === 'money-format') {
            return numberWithCommas(value??'');
        }
        return value;
    }

    return(
        <label htmlFor={Id} className="label">
            <p className="flex items-center space-x-3">
                <span>{label}</span>
                {toolTip && <Tooltip text={toolTip}/>}
            </p>
            <div className="relative flex items-center">
                <input 
                    placeholder={placeholder ?? `Enter ${label}`} 
                    type={inputType}
                    className={`input border-[#E8ECEF] border ${inputClass} ${error && 'border-red-500'} ${disabled && 'text-light-black'}`}
                    onInput={onInput}
                    value={formatValue(value)}
                    disabled={disabled}
                />
                {type === 'password' && 
                    <div className="absolute cursor-pointer right-5" onClick={onTogglePassword}>
                        {showPassword ? <EyeOff size={18}/> : <Eye size={18}/>}
                    </div>
                }
            </div>
            {error && <p className="my-1 text-sm text-red-500 label">{error}</p>}
        </label>
    );
}

export default InputField;